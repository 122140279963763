import { Footer, Header } from '@ingeniorforeningen/figurine-cms';
import { AppShell } from '@ingeniorforeningen/figurine-core';
import {
  IApiContentResponseModel,
  IApiMediaWithCropsResponseModel,
  TEnv,
} from '@ingeniorforeningen/umbraco-client';
import Head from 'next/head';
import NextImage from 'next/image';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import DefaultPageLayout from './templates/DefaultPageLayout';
import Preview from '../components/Preview';
import { layoutMap } from './templates';
import Tracker from '../tracker';
import bg from '../../public/assets/images/preview.png';

type Props = {
  children: React.ReactNode;
  headerData?: IApiContentResponseModel;
  image?: IApiMediaWithCropsResponseModel;
  page?: IApiContentResponseModel;
};

const Layout = ({ children, headerData, page, image }: Props) => {
  const { locale, isPreview } = useRouter();
  const { id, properties: pageProperties, contentType } = page || {};
  const { properties: headerProperties } = headerData || {};
  const { signUpLink, programLink, isActive } = headerProperties || {};

  const properties = {
    ...pageProperties,
    isActive,
    signUpLink: isActive ? signUpLink : undefined,
    programLink: isActive ? programLink : undefined,
  } as Record<string, any>;

  const PageLayout =
    (contentType && layoutMap[contentType] && layoutMap[contentType]) || DefaultPageLayout;

  function calculateSoMeImageUrl(): string {
    let suffix = '?format=jpg';
    let url = image?.url;
    let focal = image?.focalPoint;
    if (
      Array.isArray(properties?.socialMediaImage) &&
      properties?.socialMediaImage?.length > 0 &&
      properties?.socialMediaImage[0]?.url
    ) {
      url = properties.socialMediaImage[0].url;
      focal = properties.socialMediaImage[0].focalPoint;
    }

    if (focal) {
      suffix += `&rxy=${focal.left},${focal.top}`;
    }
    return url + suffix;
  }

  const HeaderDataWithRemovedLinkHosts = headerData
    ? JSON.parse(JSON.stringify(headerData).replace(/https:\/\/ida.dk/g, ''))
    : [];

  return (
    <AppShell
      trackingManager={Tracker.getInstance(locale)}
      theme="conferences"
      clean
      scrollToTop={false}
      polymorphic={{
        link: NextLink,
        image: NextImage,
      }}
      language={locale as 'da' | 'en'}
      environmentVariables={{ env: process.env.NEXT_PUBLIC_ENV as TEnv }}
    >
      <>
        <Head>
          <meta charSet="utf-8" />
          <title>{(properties?.metaTitle ?? properties?.header) as string}</title>
          <meta data-guid={id} />
          <meta name="description" content={properties?.metaDescription as string} />
          {(properties?.excludeFromSearchEngines as boolean) && (
            <meta name="robots" content="noindex" />
          )}
          <meta data-pagetype={contentType as string} />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />

          <meta
            property="og:title"
            content={
              (properties?.socialMediaTitle ??
                properties?.metaTitle ??
                properties?.header) as string
            }
          />
          <meta
            property="og:description"
            content={properties?.socialMediaTeaser ?? properties?.teaser?.toString()}
          />
          <meta property="og:image" content={calculateSoMeImageUrl()} />
        </Head>
        <Header data={HeaderDataWithRemovedLinkHosts} selectedSite="IDA.DK" />
        {isPreview && (
          <>
            <Preview />
          </>
        )}
        <div style={isPreview ? { backgroundImage: `url(${bg.src})` } : undefined}>
          <PageLayout properties={properties} image={image}>
            {children}
          </PageLayout>
        </div>
        <Footer />
      </>
    </AppShell>
  );
};

export default Layout;
